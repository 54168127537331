import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import colors from '../../Styles/colors';

const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
		color: `${colors.darkBlue}`,
		height: '100vh',
	},
	marginContainer: {
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
	},
	loadingIcon: {
		fontSize: '3.5rem',
		margin: 'auto',
	},
}));

const LoadingView = () => {
	const classes = useStyles();

	return (
		<Grid container className={classes.root}>
			<Grid container className={classes.marginContainer} direction="column">
				<Grid item>
					<LocalShippingIcon className={classes.loadingIcon} />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default LoadingView;
