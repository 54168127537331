import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

const Auth0ProviderHOC = ({ children }) => {
	const history = useHistory();
	const domain = process.env.REACT_APP_SCOOP_AUTH0_DOMAIN;
	const clientId = process.env.REACT_APP_SCOOP_AUTH0_CLIENT_ID;
	const redirect_uri = process.env.REACT_APP_SCOOP_AUTH0_REDIRECT_URI;
	const audience = process.env.REACT_APP_AUDIENCE;

	const onRedirectCallback = (appState) => {
		history.push(appState?.returnTo || window.location.pathname);
	};

	return (
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			redirectUri={redirect_uri}
			onRedirectCallback={onRedirectCallback}
			audience={audience}
		>
			{children}
		</Auth0Provider>
	);
};

export default Auth0ProviderHOC;
