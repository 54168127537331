import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import colors from '../../../Styles/colors';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		display: 'flex',
		backgroundColor: 'white',
		minHeight: '50px',
		fontFamily: 'Futura Light',
	},

	greenLine: {
		borderTop: `2px solid ${colors.lightGreen}`,
		marginLeft: '-25px',
		width: '250px',
		[theme.breakpoints.down(600)]: {
			width: '150px',
		},
	},
	blueLine: {
		borderTop: `2px solid ${colors.mainBlue}`,
		width: '250px',
		marginLeft: '150px',
		[theme.breakpoints.down(600)]: {
			width: '150px',
			marginLeft: '50px',
		},
		[theme.breakpoints.down(425)]: {
			marginLeft: '50px',
		},
	},
}));

const LandingDivider = () => {
	const classes = useStyles();
	return (
		<Grid container alignItems="center" className={classes.root}>
			<Grid item style={{ margin: 'auto' }}>
				<hr className={classes.greenLine} />
				<hr className={classes.blueLine} />
			</Grid>
		</Grid>
	);
};

export default LandingDivider;
