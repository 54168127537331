import React, { lazy, useEffect, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';
import './App.css';
import theme from './Styles/Theme';
import PrivateRoute from './Components/Auth/PrivateRoute';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingView from './Views/Loading/LoadingView';
import { Provider } from 'react-redux'
import appStore from './Store/appStore';
import QuickHelp from './Views/Landing/BoxPages/QuickHelp';
import ScrollToTop from './Components/Router/ScrollToTop';

const LandingRouter = lazy(() => import('./Views/Landing/LandingRouter'));
const Profile = lazy(() => import('./Views/Profile/Profile'));
const AboutUs = lazy(() => import('./Views/Landing/LandingPages/AboutUs'));
const NotFoundPage = lazy(() => import('./Views/Landing/NotFoundPage'));
const ErrorReporting = lazy(() => import('./Views/ErrorReporting/ErrorReporting'));
const SignupConfirmation = lazy(() => import('./Views/Confirmation/SignupConfirmation'));
const MobileHome = lazy(() => import('./Views/Landing/Mobile/MobileHome'));
const GettingStarted = lazy(() => import('./Views/Landing/BoxPages/GettingStarted'));
const Driver = lazy(() => import('./Views/Driver/Driver'));

const getQuery = () => {
	if (typeof window !== 'undefined') {
		return new URLSearchParams(window.location.search);
	}
	return new URLSearchParams();
};

const getQueryStringVal = (key) => {
	return getQuery().get(key);
};

function App() {
	const { isLoading } = useAuth0();

	// Save sign up codes to local storage
	useEffect(() => {
		const buildingCode = getQueryStringVal('buildingCode');
		if (buildingCode) {
			localStorage.setItem('buildingCode', buildingCode);
		}

		// Set default clarity production tag
		if (process.env.REACT_APP_AUDIENCE === 'https://scoopreturns.com') {
			window.clarity("set", "Production", "True");
		} else {
			window.clarity("set", "Production", "False");
		}
	}, [])

	if (isLoading) {
		return <LoadingView />;
	}

	return (
		<div className="App">
			<Suspense fallback={<LoadingView />}>
				<MuiThemeProvider theme={theme}>
					<Provider store={appStore}>
						<ScrollToTop>
							<BrowserRouter>
								<Switch>
									<Route path={['/about']} component={AboutUs} />
									<Route
										exact
										path={['/alternateHome']}
										component={MobileHome}
									/>
									<Route path={'/confirmation'} component={SignupConfirmation} />
									<Route path={'/getting-started'} component={GettingStarted} />
									<Route path={'/quick-help'} component={QuickHelp} />
									<Route
										path={'/report/:lockerId'}
										component={ErrorReporting}
									/>
									<PrivateRoute path={'/profile'} component={Profile} />
									<Route
										path={['/', '/mobile/*']}
										exact
										component={LandingRouter}
									/>
									<Route path={['/driver']} component={Driver} />
									<Route path={'/*'} component={NotFoundPage} />
								</Switch>
							</BrowserRouter>
						</ScrollToTop>
					</Provider>
				</MuiThemeProvider>
			</Suspense>
		</div>
	);
}

export default App;
