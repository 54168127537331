import axios from 'axios';

const useQuestionsService = () => {

    const sendQuestion = async (name, email, subject) => {
        const response = await axios['post']('/api/questions', {
            name: name,
            email: email,
            subject: subject
        });

        return response;
    }

    return { sendQuestion }
}

export default useQuestionsService;