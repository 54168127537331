const colors = {
	white: '#FFFFFF',
	black: '#000000',
	mainBlue: '#2E06E9',
	darkBlue: '#000083',
	fadedBlue: '#3F51B5',
	lightGreen: '#4BBBA7',
	yellow: '#F8B356',
	gray: '#F5F5F5',
	lightGray: '#D5D6D7',
	darkGray: '#242323',
	profileBackgroundGray: '#F6F7FB',
	errorRed: '#ff0033',
	mediumGrey: '#757575'
};

export default colors;
