import { Dialog, Grid, makeStyles, DialogTitle, DialogContent, DialogActions, useMediaQuery, Fade } from '@material-ui/core';
import ScoopLogo from '../../logo_scoop.svg';
import React from 'react'
import colors from '../../Styles/colors';

const useStyles = makeStyles((theme) => ({
	title: {
		backgroundColor: colors.mainBlue,
		color: colors.white,
	},
	scoopTitle: {
		fontSize: '2rem',
		letterSpacing: '.3rem',
		textAlign: 'center',
		[theme.breakpoints.down(960)]: {
			letterSpacing: 'unset'
		},
	},
	DialogFooter: {
		justifyContent: 'center'
	},
	Dialog: {
		maxWidth: '100vw'
	},
	dialogPaper: {
		position: 'absolute',
		top: '0',
		marginTop: '5vh',
		minWidth: '500px',
		[theme.breakpoints.down(960)]: {
			position: 'relative',
			margin: 0,
			maxWidth: 'none',
			width: '100vw',
			marginTop: '0',
			height: '100%',
			minWidth: 'unset'
		},
	}
}));

const BaseDialog = ({showDialog, setShowDialog, headerText, content, footer, maxWidth = false, disableBackdropClick = true, noMargin = false}) => {

	const isMobile = useMediaQuery("(max-width:960px)");

    const classes = useStyles();

	const closeDialog = () => {
		if(setShowDialog) {
			setShowDialog(false);
		}
	}

    return (
        <Dialog
			TransitionComponent={Fade}
			transitionDuration={500}
			fullScreen={isMobile}
			classes={{ paper: classes.dialogPaper }}
			className={classes.Dialog}
			open={showDialog}
			onClose={closeDialog}
			maxWidth={maxWidth}
			disableBackdropClick={disableBackdropClick}
			disableEscapeKeyDown
		>
			{
				headerText &&
				<DialogTitle className={classes.title}>
					<Grid item xs={12}>
						<Grid container alignItems="center" justify="center">
							<span className={classes.scoopTitle}>{ headerText }</span>
							<img
								style={{marginTop: '-5px', marginLeft: '5px'}}
								className={classes.scoopTitleLogo}
								src={ScoopLogo}
								alt="Scoop Logo"
								width={isMobile ? 40 : 50}
								height={isMobile ? 40 : 50}
							/>
						</Grid>
					</Grid>
				</DialogTitle>
			}
			<DialogContent style={{padding: noMargin ? '0px' : '8px 24px'}}>
				{ content() }
			</DialogContent>
			<DialogActions className={classes.DialogFooter}>
				{ footer ? footer() : null }
			</DialogActions>
		</Dialog>
    );

}

export default BaseDialog;