import React from 'react';
import {
	Grid,
	makeStyles,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '@material-ui/core';
import colors from '../../Styles/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
	root: {
		fontFamily: 'Avenir Light',
	},
	accordionSummary: {
		backgroundColor: `${colors.mainBlue}`,
		color: 'white',
		borderBottom: '1px solid white',
	},
	accordionSummaryExpandIcon: {
		order: -1,
	},
	expandIcon: {
		paddingTop: '1px',
		color: `${colors.mainBlue}`,
	},
	expandIconRotated: {
		paddingTop: '1px',
		color: `${colors.mainBlue}`,
		marginLeft: '-20px',
	},
	summaryHeader: {
		fontFamily: 'Avenir Light',
		fontSize: '1.15rem',
		marginLeft: theme.spacing(2),
	},
	expandIconDiv: {
		height: '25px',
		width: '25px',
		backgroundColor: 'white',
		borderRadius: 50,
	},
}));

const faqList = [
	{
		header: 'Where do I use Scoop?',
		content:
			'You can use Scoop at any locker location! Once you’re signed up, you can make returns at any Scoop Locker in just seconds.',
	},
	{
		header: 'How do I use Scoop?',
		content:
			'To start using Scoop, sign up and make an account. As long as you can access a Scoop Locker- you can use Scoop and make returns. It’s that easy.',
	},
	{
		header: 'Do I need to use the Scoop Locker at a scheduled time?',
		content:
			'There’s no need to return around a schedule with Scoop. The Locker is available to you at all times, so you can print and drop off whenever works for you.',
	},
	{
		header: `What couriers can I use?`,
		content:
			'At the moment, Scoop supports USPS and UPS. Additionally, Scoop also supports FedEx at some locations. We will be looking to add additional couriers later this year.',
	},
	{
		header: 'How much does Scoop cost?',
		content:
			'Depending on your subscription tier you may get charged differently for making returns. If your building is signed up for Scoop- you pay no extra charges to use Scoop! Sign up with Scoop to see our pricing tiers and discounts.',
	},
	{
		header: 'How do I switch tiers on my Scoop subscription?',
		content:
			'In order to switch tiers, go to your settings and select another tier to access different benefits.',
	},
	{
		header: 'Can anyone use the Scoop locker?',
		content: `Only Scoop users or Residents can use the Scoop locker. Users are issues unique QR codes with timed expiration for each return we can make sure users and returns can use Scoop safely.`,
	},
];

const FAQAccordion = () => {
	const classes = useStyles();

	const mapFAQs = () => {
		return faqList.map((faq) => (
			<Accordion key={faq.header}>
				<AccordionSummary
					aria-controls="panel1a-content"
					expandIcon={
						<div className={classes.expandIconDiv}>
							<ExpandMoreIcon className={classes.expandIcon} />
						</div>
					}
					className={classes.accordionSummary}
					classes={{ expandIcon: classes.accordionSummaryExpandIcon }}
				>
					<Grid container alignItems="center">
						<span className={classes.summaryHeader}>{faq.header}</span>
					</Grid>
				</AccordionSummary>
				<AccordionDetails>
					<p className={classes.summaryHeader}>{faq.content}</p>
				</AccordionDetails>
			</Accordion>
		));
	};

	return <div className={classes.root}>{mapFAQs()}</div>;
};

export default FAQAccordion;
