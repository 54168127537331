const initialState = {
  returnCredits: null,
  subscriptionTier: null,
  subscriptionName: null,
  currentSubscriptionTier: null,
  currentSubscriptionName: null,
  subText: null,
  hasSelectedSubscription: null,
  freeReturnCredits: null,
  paymentMethod: null,
  openSubscriptionDialog: false,
};

export const subscriptionActions = {
  LoadSubscriptionDetails: "SUBSCRIPTION_LOAD_DETAILS",
  UserSelectedSubscription: "SUBSCRIPTION_SELECTED",
  ChangePaymentMethod: "SUBSCRIPTION_PAYMENT_CHANGE",
  ChangeSubText: "SUBSCRIPTION_SUB_TEXT",
  UpdateSubscriptionTiers: "SUBSCRIPTION_UPDATE_TIER",
  SetReturnCredits: "SUBSCRIPTION_SET_CREDITS",
  OpenSubscriptionDialog: "SUBSCRIPTION_OPEN_DIALOG",
};

export default function subscriptionReducer(state = initialState, action) {
  switch (action.type) {
    case subscriptionActions.LoadSubscriptionDetails:
      if (!action.payload?.subscriptionName) {
        action.payload.subscriptionName = "None";
      }
      return { ...state, ...action.payload };
    case subscriptionActions.SetReturnCredits:
      return {
        ...state,
        returnCredits:
          action.payload.returnCredits !== undefined
            ? action.payload.returnCredits
            : state.returnCredits,
        freeReturnCredits:
          action.payload.freeReturnCredits !== undefined
            ? action.payload.freeReturnCredits
            : state.freeReturnCredits,
      };
    case subscriptionActions.UserSelectedSubscription:
      return {
        ...state,
        subscriptionTier: action.payload,
        hasSelectedSubscription: true,
      };
    case subscriptionActions.ChangePaymentMethod:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case subscriptionActions.OpenSubscriptionDialog:
      return {
        ...state,
        openSubscriptionDialog: action.payload,
      };
    default:
      return state;
  }
}
