const initialState = {
    packagesUploaded: null,
    labelsPrinted: null,
    returnValue: null,
    lastUpdatedTime: null
}

export const statsActions = {
    AddPackagesUploaded: 'STATS_ADD_PACKAGES_UPLOADED',
    AddLabelsPrinted: 'STATS_ADD_LABELS_PRINTED',
    AddReturnValue: 'STATS_ADD_RETURN_VALUE',
    SetAllValues: 'STATS_SET_ALL_VALUES'
}

export default function statsReducer (state = initialState, action) {
    switch(action.type) {
        case statsActions.AddPackagesUploaded:
            console.log(action.payload);
            console.log(state);
            return {
                ...state,
                packagesUploaded: state.packagesUploaded + action.payload.value
            };

        case statsActions.AddLabelsPrinted:
            return {
                ...state,
                labelsPrinted: state.labelsPrinted + action.payload.value
            };

        case statsActions.AddReturnValue:
            return {
                ...state,
                returnValue: state.returnValue + action.payload.value
            };

        case statsActions.SetAllValues:
            console.log(action.payload);
            return {
                packagesUploaded: action.payload.packagesUploaded,
                labelsPrinted: action.payload.labelsPrinted,
                returnValue: action.payload.returnValue,
                lastUpdatedTime: new Date()
            };

        default:
            return state;
    }
}