import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import Auth0ProviderHOC from './Components/Auth/Auth0ProviderHOC';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
	<Router>
		<Auth0ProviderHOC>
			<App />
		</Auth0ProviderHOC>
	</Router>,
	document.getElementById('root')
);

serviceWorker.unregister();