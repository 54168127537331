import { createMuiTheme } from '@material-ui/core/styles';
import colors from './colors';

const theme = createMuiTheme({
	typography: {
		fontFamily: ['Avenir Light'],
		button: {
			textTransform: 'none'
		},
		h1: {
			paddingTop: '12px',
			fontSize: '5.45rem',
			letterSpacing: '1rem',
			'@media (min-width:1380px)': {
				fontSize: '6.45rem',
				letterSpacing: '1rem',
			},
			'@media (max-width:1380px)': {
				fontSize: '5.45rem',
				letterSpacing: '1rem',
			},
			'@media (max-width:1096px)': {
				fontSize: '4.5rem',
				letterSpacing: '.75rem',
			},
			'@media (max-width:649px)': {
				fontSize: '4rem',
				letterSpacing: '.75rem',
			},
			'@media (max-width:425px)': {
				paddingTop: '6px',
				fontSize: '3.5rem',
				letterSpacing: '.6rem',
			},
			'@media (max-width:376px)': {
				paddingTop: '6px',
				fontSize: '3.25rem',
				letterSpacing: '.6rem',
			},
			'@media (max-width:350px)': {
				fontSize: '2.5rem',
				letterSpacing: '.5rem',
			},
		},
		h2: {
			fontFamily: 'Futura Light',
			fontSize: '1.75rem',
			fontWeight: '500',
			'@media (max-width:1096px)': {
				fontSize: '2rem',
				letterSpacing: '.75rem',
			},
			'@media (max-width:649px)': {
				fontSize: '1.5rem',
			},
		},
		h3: {
			fontFamily: 'Futura Light',
			fontWeight: 'bold',
			fontSize: '1.125rem',
			'@media (min-width:1380px)': {
				fontSize: '1.33rem',
			},
			'@media (max-width:649px)': {
				fontSize: '1.15rem',
			},
			'@media (max-width:425px)': {
				fontSize: '1rem',
				paddingLeft: '16px',
				paddingRight: '16px',
			},
			'@media (max-width:376px)': {
				fontSize: '.9rem',
				paddingLeft: '16px',
				paddingRight: '16px',
			},
			'@media (max-width:325px)': {
				fontSize: '.8rem',
				paddingLeft: '16px',
				paddingRight: '16px',
			},
		},
		h4: {
			fontSize: '1.3rem',
			'@media (max-width:649px)': {
				fontSize: '1rem',
			},
		},
	},
	palette: {
		primary: {
			main: colors.mainBlue,
			contrastText: colors.white
		},
		secondary: {
			main: colors.lightGreen,
			contrastText: colors.white
		},
		default: {
			main: colors.gray,
			contrastText: colors.white
		}
	},
	overrides: {
		MuiTooltip: {
			tooltip: {
				backgroundColor: colors.mainBlue,
				fontFamily: 'Futura Light',
				fontSize: '1.2rem',
				textAlign: 'center',
				border: '1px solid black',
				boxShadow: '0px 0px 5px 2px black',
			}
		}
	}
});

export default theme;
