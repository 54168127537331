import { Button, Grid, makeStyles } from '@material-ui/core';
import React from 'react'
import BaseDialog from '../BaseDialog';

const useStyles = makeStyles((theme) => ({
    chatBox: {
        border: 'none',
        width: 'calc(100% + 2px)',
        marginLeft: '-1px',
        marginTop: '-1px',
        height: '500px',
        [theme.breakpoints.down(960)]: {
			height: 'calc(100vh - 60px)'
		},
    },
    root: {
        width: '100%',
        overflowX: 'hidden'
    },
    footer: {
        height: '40px'
    }
}))

const LiveChat = ({setShowDialog, showDialog}) => {

    const classes = useStyles();

    const content = () => (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <iframe 
                sandbox="allow-scripts allow-forms allow-popups allow-same-origin" 
                className={classes.chatBox} 
                src="https://tawk.to/chat/60b90e3ede99a4282a1b3eaa/1f79dv8o2" />
            </Grid>
        </Grid>
    )

    const footer = () => (
        <Grid container className={classes.footer}>
            <Grid item xs={12}>
                <Button variant="contained" fullWidth color="primary" onClick={() => setShowDialog(false)}>Close Live Chat</Button>
            </Grid>
        </Grid>
    )

    return (
        <BaseDialog 
            content={content}
            footer={footer}
            setShowDialog={setShowDialog}
            showDialog={showDialog}
            noMargin
        />
    )
}

export default LiveChat;