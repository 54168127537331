const initialState = {
  firstName: "",
  lastName: "",
  address: "",
  defaultLocationAddress1: "",
  defaultLocationAddress2: "",
  isResident: false,
  loaded: false,
  showTour: false,
  showSchedulePickUp: false,
};

export const userActions = {
  UpdateProfile: "USER_UPDATE_PROFILE",
  UpdateLocation: "USER_UPDATE_LOCATION",
  LoadState: "USER_LOAD_STATE",
  HideFeedback: "USER_HIDE_FEEDBACK",
  FinishTour: "USER_FINISH_TOUR",
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case userActions.UpdateProfile:
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        address: action.payload.address,
        unitNumber: action.payload.unitNumber,
        phoneNumber: action.payload.phoneNumber,
      };

    case userActions.UpdateLocation:
      return {
        ...state,
        defaultLocationAddress1: action.payload.defaultLocationAddress1,
        defaultLocationAddress2: action.payload.defaultLocationAddress2,
        showSchedulePickUp: action.payload.showSchedulePickUp,
      };

    case userActions.LoadState:
      return {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        address: action.payload.address,
        defaultLocationAddress1: action.payload.defaultLocationAddress1,
        defaultLocationAddress2: action.payload.defaultLocationAddress2,
        isResident: action.payload.isResident,
        unitNumber: action.payload.unitNumber,
        showTour: action.payload.showTour,
        phoneNumber: action.payload.phoneNumber,
        buildingName: action.payload.buildingName,
        amazonLabelLines: action.payload.amazonLabelLines,
        showFeedback: action.payload.showFeedback,
        initialFeedback: action.payload.initialFeedback,
        defaultLockerId: action.payload.defaultLockerId,
        loaded: true,
        lockerReleaseDate: action.payload.lockerReleaseDate,
        showSchedulePickUp: action.payload.showSchedulePickUp,
      };

    case userActions.HideFeedback:
      return {
        ...state,
        showFeedback: false,
      };

    case userActions.FinishTour:
      return {
        ...state,
        showTour: false,
      };

    default:
      return state;
  }
}
