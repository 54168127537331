import React from "react";
import BaseDialog from "../BaseDialog";
import { Grid, Button } from '@material-ui/core'

const ContactCompleteDialog = ({showDialog, setShowDialog}) => {

    const content = () => (
        <Grid container justify="center">
            <Grid item xs={12} style={{textAlign: 'center'}}>
                <h2>Thanks for reaching out.</h2>
                <h3>You will hear back from us soon!</h3>
            </Grid>
        </Grid>
    )

    const footer = () => (
        <Grid container justify="center">
            <Grid item>
                <Button variant="contained" color="default" onClick={() => setShowDialog(false)}>Close</Button>
            </Grid>
        </Grid>
    )

    return (
        <BaseDialog 
            content={content}
            footer={footer}
            showDialog={showDialog}
            setShowDialog={setShowDialog}
            headerText="Message Sent"
        />
    )

}

export default ContactCompleteDialog;