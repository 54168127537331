import React from 'react';
import { Button, withStyles } from '@material-ui/core';

import colors from '../../Styles/colors';

const styles = {
	root: {
		backgroundColor: `${colors.mainBlue}`,
		color: `${colors.white}`,
		fontSize: '1.05rem',
		fontWeight: '300',
		'&:hover': {
			backgroundColor: `${colors.mainBlue}`,
		},
		'@media (min-width:1380px)': {
			fontSize: '1.24rem',
		},
		'@media (max-width:425px)': {
			fontSize: '1.25rem',
		},
		'@media (max-width:325px)': {
			fontSize: '1.15rem',
		},
	},
};

const TransparentNavButton = (props) => {
	const { classes, ...other } = props;
	return (
    <Button disableRipple className={classes.root} {...other} />
	);
};

export default withStyles(styles)(TransparentNavButton);
