import { combineReducers, createStore } from 'redux'
import statsReducer from './reducers/statsReducer';
import userReducer from './reducers/userReducer';
import subscriptionReducer from './reducers/subscriptionReducer';

const appStore = createStore(
    combineReducers({
        user: userReducer,
        stats: statsReducer,
        subscription: subscriptionReducer
    })
);

export default appStore;