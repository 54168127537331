import { Grid, makeStyles,TextField, Button, withStyles, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import LandingNavbar from '../LandingComponents/LandingNavbar';
import Footer from '../LandingComponents/Footer';
import colors from '../../../Styles/colors';
import FAQAccordion from '../../../Components/Accordion/FAQAccordion';
import LandingDivider from '../LandingComponents/Divider';
import useQuestionsService from '../../../Services/useQuestionsService';
import ContactCompleteDialog from '../../../Components/Dialogs/InfoDialogs/ContactCompleteDialog'
import LiveChat from '../../../Components/Dialogs/MiscDialogs/LiveChat'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        fontFamily: 'Avenir Light',
        overflowX: 'hidden'
    },
    header: {
        paddingTop: '100px',
        textAlign: 'center'
    },
    help: {
        color: colors.mainBlue,
        fontSize: '3.5rem',
        fontFamily: 'Avenir Light',
        marginBottom: '0px'
    },
    subHeader: {
        fontSize: '1.5rem',
        marginTop: '0px',
        padding: '10px',
        paddingBottom: '0px',
        marginBottom: '0px',
    },
    subHeader2: {
        fontSize: '1.5rem',
        marginTop: '0px',
        marginBottom: '20px',
        padding: '10px',
        paddingBottom: '0px',
        paddingTop: '0px',
    },
    subHeader3: {
        fontSize: '1rem',
        marginTop: '20px',
        marginBottom: '25px',
        padding: '9px',
        paddingLeft: '25px',
        paddingRight: '25px',
        color: colors.mainBlue,
        border: `1px solid ${colors.mainBlue}`
    },
    contactForm: {
        padding: '10px',
        paddingTop: '20px',
        paddingBottom: '50px',
        borderRadius: '50px'
    },
    smallHeader: {
        fontFamily: 'Avenir Light',
        fontSize: '1.8rem',
        textAlign: 'center',
        marginBottom: 0,
        marginTop: 10
    }
}));

const CustomTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colors.mainBlue,
            },
            '&:hover fieldset': {
                borderWidth: '2px',
                borderColor: colors.mainBlue
            },
            '&.Mui-focused fieldset': {
                borderColor: colors.mainBlue
            },
        },
    },
})(TextField)

const QuickHelp = () => {
    const classes = useStyles();

    const questionsService = useQuestionsService();

    const [formData, setFormData] = useState({
        name: null,
        email: null,
        message: null
    });
    
    const [showCompleteDialog, setShowCompleteDialog] = useState(false);
    const [showLiveChat, setShowLiveChat] = useState(false);

    const [liveChatAvailable, setLiveChatAvailable] = useState(false);
    const [emailLoading, setEmailLoading] = useState(false);
    const [liveChatLoading, setLiveChatLoading] = useState(false);

    const validate = () => {
        let isValid = true;

        if(formData.name === null || formData.email === null || formData.message === null) {
            isValid = false;
        }

        setFormData({
            ...formData,
            message: formData.message === null ? '' : formData.message,
            name: formData.name === null ? '' : formData.name,
            email: formData.email === null ? '' : formData.email,
        });

        return isValid;
    }

    const sendEmail = async () => {
        setLiveChatLoading(false);
        if(
            validate() &&
            formData.name !== '' && 
            formData.email !== '' && 
            formData.message !== '') {
            setEmailLoading(true);
            try{
                await questionsService.sendQuestion(formData.name, formData.email, formData.message);
                setFormData({
                    name: null,
                    email: null,
                    message: null
                });
                setShowCompleteDialog(true);
            }catch {
                console.log("Error sending question");
            }
            setEmailLoading(false);
        }
    }

    useEffect(() => {
        if(window.Tawk_API) {
            try{
                setTawkStatus();
            }catch{
                window.Tawk_API.onLoad = function() {
                    console.log("Tawk Loaded");
                    setTawkStatus();
                }
            }
            
        }
    }, [])

    const setTawkStatus = () => {
        const status = window.Tawk_API.getStatus();

        if(status === 'online') {
            setLiveChatAvailable(true);
        } else {
            setLiveChatAvailable(false);
        }
    }

    const startLiveChat = () => {
        setShowLiveChat(true);
    }

    return (
        <>
            <Grid container className={classes.root} justify="center">
                <LandingNavbar />
                <Grid item xs={12} className={classes.header}>
                    <h1 className={classes.help}>Support</h1>
                    <h3 className={classes.subHeader}>
                        Questions, Issues, Feedback
                    </h3>
                    <h3 className={classes.subHeader2}>
                        We Are Here to Help
                    </h3>
                    <Grid container justify="center">
                        <Grid item xs={12}>
                            <span className={classes.subHeader3}>
                                Contact Us Below
                            </span>
                        </Grid>
                    </Grid>
                    
                </Grid>
                <Grid item lg={6} xs={12} className={classes.contactForm}>
                    <Grid container justify="center" spacing={3}>
                        <Grid item xs={12}>
                            <h3 className={classes.smallHeader}>
                                FAQ's
                            </h3>
                        </Grid>
                        <Grid item xs={12}>
                            <FAQAccordion />
                        </Grid>
                        <Grid item xs={12}>
                            <LandingDivider />
                        </Grid>
                        <Grid item xs={12}>
                            <h3 className={classes.smallHeader}>
                                Questions
                            </h3>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextField 
                                value={formData.name || ""}
                                fullWidth
                                size='medium'
                                label="Name"
                                variant="outlined"
                                required
                                error={formData.name === ''}
                                helperText={formData.name === '' && "Name is required"}
                                onChange={(e) => setFormData({...formData, name: e.target.value})}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextField
                                value={formData.email || ""}
                                fullWidth
                                size='medium'
                                label="Email"
                                variant="outlined"
                                required
                                error={formData.email === ''}
                                helperText={formData.email === '' && "Email is required"}
                                onChange={(e) => setFormData({...formData, email: e.target.value})}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextField
                                value={formData.message || ""}
                                fullWidth
                                size='medium'
                                label="Message"
                                multiline
                                rows={5}
                                variant="outlined"
                                required
                                error={formData.message === ''}
                                helperText={formData.message === '' && "Message is required"}
                                onChange={(e) => setFormData({...formData, message: e.target.value})}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item md={6} xs={12}>
                                    <Button color="primary" variant="contained" fullWidth size="large" onClick={sendEmail} disabled={emailLoading || liveChatLoading}>
                                        { !emailLoading ? "Send Email" : <CircularProgress size={27}/> }
                                    </Button>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Button color="primary" onClick={startLiveChat} variant="contained" fullWidth size="large" disabled={emailLoading || liveChatLoading || !liveChatAvailable}>
                                        { !liveChatLoading ? 
                                            (liveChatAvailable ? "Start Live Chat" : "Live Chat Unavailable") : 
                                            <CircularProgress size={27}/> 
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Footer />
            </Grid>
            <ContactCompleteDialog 
                setShowDialog={setShowCompleteDialog}
                showDialog={showCompleteDialog}
            />
            <LiveChat 
                setShowDialog={setShowLiveChat}
                showDialog={showLiveChat}
            />
        </>
    )
}

export default QuickHelp;