import React from 'react';
import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import LoadingView from '../../Views/Loading/LoadingView';

const PrivateRoute = ({ component, ...args }) => (
	<Route
		component={withAuthenticationRequired(component, {
			onRedirecting: function showLoadingView() {
				return (
					<LoadingView />
				);
			},
		})}
		{...args}
	/>
);

export default PrivateRoute;
