import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import colors from '../../../Styles/colors';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		color: `${colors.white}`,
		fontFamily: 'Avenir Light',
		height: '100%'
	},
	contactSectionContainer: {
		margin: 'auto',
		paddingTop: theme.spacing(6),
		paddingBottom: theme.spacing(6),
		backgroundColor: '#07081F',
	},

	contactIcon: {
		marginRight: theme.spacing(2),
	},
	contactTrademark: {
		fontSize: '.9rem',
	},
	mobileHide: {
		[theme.breakpoints.down(600)]: {
			display: 'none',
		},
	},
	termsConditions: {
		color: 'white',
		textDecoration: 'none',
		display: 'block',
		paddingTop: '5px'
	},
	footerItem: {
		textAlign: 'center',
		[theme.breakpoints.down('xs')]: {
			marginBottom: theme.spacing(1),
		},
	},
}));

const Footer = () => {
	const classes = useStyles();

	return (
		<Grid container className={classes.root}>
			<Grid
				container
				className={classes.contactSectionContainer}
				alignItems="center"
			>
				<Grid item xs={12} sm={6} className={classes.footerItem}>
					<span>Email Us: info@scoopreturns.com</span>
					<span className={classes.mobileHide}> | Miami, FL, USA</span>
				</Grid>
				<Grid item xs={12} sm={6} className={classes.footerItem}>
					<span className={classes.contactTrademark}>
						&copy; 2023 SCOOP RETURNS INC.
					</span>
					<a className={classes.termsConditions} href={`${process.env.REACT_APP_API_URL}/docs/terms`}>Terms and Conditions</a>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Footer;
