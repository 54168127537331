import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Button } from '@material-ui/core';
import colors from '../../../Styles/colors';
import TransparentNavButton from '../../../Components/Buttons/TransparentNavButton';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useAuth0 } from '@auth0/auth0-react';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		backgroundColor: `${colors.mainBlue}`,
		padding: '0px 48px',
		height: '15vh',
		minHeight: '90px',
		maxHeight: '100px',
		color: `${colors.white}`,
		position: 'fixed',
		zIndex: '3',
		transition: 'all 0.5s ease'
	},
	toggle: {
		width: '100%',
	},
	navbarLinkContainer: {
		justifyItems: 'end',
		alignItems: 'end',
		marginRight: theme.spacing(2),
	},
	navbarLogoLink: {
		fontSize: '1.25rem',
		fontWeight: '300',
		letterSpacing: '.25em',
	},
	collapsableLink: {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	hashLink: {
		textDecoration: 'none',
	},
	navbarButton: {
		backgroundColor: `${colors.mainBlue}`,
		color: `${colors.white}`,
		fontSize: '1.05rem',
		fontWeight: '300',
		'&:hover': {
			backgroundColor: `${colors.mainBlue}`,
		},
	},
}));

const LandingNavbar = () => {
	const classes = useStyles();

	const { loginWithRedirect, isAuthenticated } = useAuth0();
	const [hasScrolled, setHasScrolled] = useState(false);

	const getAuthenticationText = () => {
		if (isAuthenticated) {
			return 'Dashboard';
		} else {
			if (localStorage.getItem('hasCreatedAccount')) {
				return 'Login';
			} else {
				return 'Sign Up';
			}
		}
	}

	const redirectLogin = () => {
		if(localStorage.getItem('hasCreatedAccount')) {
			loginWithRedirect({});
		} else {
			loginWithRedirect({ screen_hint: 'signup' })
		}
	}

	useEffect(() => {
		window.onscroll = () => {
			if (window.scrollY === 0) {
				setHasScrolled(false);
			} else {
				setHasScrolled(true);
			}
		}
	}, [])

	return (
		<Grid
			item
			container
			alignItems="center"
			justify="space-between"
			className={classes.root}
			style={{
				boxShadow: hasScrolled ? '0 5px 10px 0 rgba(0,0,0,.5)' : ''
			}}
		>
			<Grid
				container
				alignItems="center"
				justify="center"
				className={classes.toggle}
			>
				<Grid item xs={2}>
					<Button
						disableRipple
						component={Link}
						to={{
							pathname: '/',
						}}
						className={classes.navbarButton}
					>
						<span className={classes.navbarLogoLink}>SCOOP</span>
					</Button>
				</Grid>
				<Grid item xs={10}>
					<Grid
						container
						direction="row"
						alignItems="flex-start"
						justify="flex-end"
						spacing={4}
						className={classes.navbarLinkContainer}
					>
						<Grid item className={classes.collapsableLink}>
							<HashLink smooth to="/#info" className={classes.hashLink}>
								<TransparentNavButton>How it works</TransparentNavButton>
							</HashLink>
						</Grid>
						<Grid item className={classes.collapsableLink}>
							<HashLink smooth to="/#benefits" className={classes.hashLink}>
								<TransparentNavButton>Benefits</TransparentNavButton>
							</HashLink>
						</Grid>
						<Grid item className={classes.collapsableLink}>
							<HashLink smooth to="/#hardware" className={classes.hashLink}>
								<TransparentNavButton>Hardware</TransparentNavButton>
							</HashLink>
						</Grid>
						<Grid item className={classes.collapsableLink}>
							<HashLink to="/about#us" className={classes.hashLink}>
								<TransparentNavButton>About</TransparentNavButton>
							</HashLink>
						</Grid>
						<Grid item className={classes.collapsableLink}>
							<HashLink smooth to="/#contact" className={classes.hashLink}>
								<TransparentNavButton>Contact</TransparentNavButton>
							</HashLink>
						</Grid>

						<Grid item>
							<TransparentNavButton onClick={redirectLogin}>
								{getAuthenticationText()}
							</TransparentNavButton>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default LandingNavbar;
